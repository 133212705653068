<template>
    <data-iterator
        ref="iterator"
        class="mt-5"
        :api="api"
        :filters.sync="filters"
        :fixed-filters="{ userProfile: { exact: user } }"
    >
        <template v-slot:after>
            <slot name="after"></slot>
        </template>

        <template slot-scope="{ items }">
            <v-expansion-panels v-model="activeTab" accordion>
                <v-expansion-panel v-for="item in items" :key="item.id">
                    <form-expansion-header
                        :tab-errors="tabErrors"
                        name="address"
                    >
                        <v-row>
                            <v-col cols="4" class="overflow-x-hidden">{{
                                item.name
                            }}</v-col>
                            <v-col cols="5" class="overflow-x-hidden">{{
                                item.fullAddress
                            }}</v-col>
                            <v-col cols="3">{{ item.subAddress }}</v-col>
                        </v-row>
                    </form-expansion-header>
                    <v-expansion-panel-content>
                        <form-panel>
                            <addresses-form-part
                                :inner-form="true"
                                :value="getItem(item)"
                                @updateAddressList="
                                    $refs.iterator.updateItems(true, true)
                                "
                                @updateListAfterDelete="
                                    $refs.iterator.updateItems(true, true)
                                "
                            ></addresses-form-part>
                        </form-panel>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
    </data-iterator>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import routeType from '@/router/routeType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import FormBase from '@/components/mixins/FormBase'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormExpansionHeader from '@/components/form/FormExpansionHeader'
import FormPanel from '@/components/form/FormPanel'
import AddressesFormPart from '@/components/pages/addresses/AddressesFormPart'
import cloneDeep from 'lodash/cloneDeep'
import AddressesHeaders from '@/pages/users/mixins/AddressesHeaders'

export default {
    components: {
        FormExpansionHeader,
        FormPanel,
        AddressesFormPart,
    },

    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        FormBase,
        FormTabMixin,
        AddressesHeaders,
    ],

    props: {
        userId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            api: RestApiType.USER_ADDRESSES,
            activeTab: undefined,
        }
    },
    computed: {
        user() {
            return this.userId
        },
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.USER_ADDRESS_EDIT,
                params: { userId: this.userId, addressId: item.id },
            }
        },

        getItem(item) {
            return cloneDeep(item)
        },
    },
}
</script>
