<template>
    <v-expansion-panel-header>
        <v-badge
            left
            color="error"
            :content="tabErrors[name]"
            :value="tabErrors[name] > 0"
            style="max-width: calc(100% - 48px)"
        >
            <slot>
                {{ title }}
            </slot>
        </v-badge>
    </v-expansion-panel-header>
</template>

<script>
export default {
    props: {
        tabErrors: {
            type: Object,
            default: () => ({}),
        },
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
    },
}
</script>
