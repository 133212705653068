<template>
    <div>
        <page-title>{{ title }}</page-title>
        <user-route-tabs :user-id="userId"></user-route-tabs>
        <h3 class="my-5">{{ $t('labels.user_addresses') }}</h3>
        <user-addresses-table
            v-if="isSuperAdmin"
            ref="addressTable"
            :user-id="userId"
        >
            <template v-slot:after>
                <v-btn
                    v-if="!isCreate"
                    color="success"
                    class="mt-5"
                    @click="isCreate = true"
                >
                    {{ $t('actions.create') }}
                </v-btn>
                <form-panel
                    v-if="isCreate"
                    :title="$t('actions.create')"
                    class="mt-5"
                >
                    <addresses-form-part
                        :inner-form="true"
                        :value="{}"
                        :user="user"
                        @cancel="isCreate = false"
                        @updateList="onCreate()"
                    ></addresses-form-part>
                </form-panel>
            </template>
        </user-addresses-table>

        <h3 class="my-5">{{ $t('labels.company_addresses') }}</h3>
        <overlay :show="busy">
            <v-data-table
                v-if="user && user.company"
                :value="selectedCompanyAddresses"
                :items="companyAddresses"
                :headers="headers"
                class="data-table"
                show-select
                disable-filtering
                disable-pagination
                must-sort
                hide-default-footer
                item-key="id"
                @input="onInput"
            >
            </v-data-table>
        </overlay>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import routeType from '@/router/routeType'
import SelectApiClienType from '@/api/SelectApiClientType'
import TitleMixinGenerator from '@/components/mixins/page-title/TitleMixinGenerator'
import UserRouteTabs from '@/components/pages/users/RouteTabs'
import UserAddressesTable from '@/components/pages/userAddresses/Table'
import RestApiCollection from '@/api/RestApiCollection'
import MapperCollection from '@/services/mapper/MapperCollection'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormPanel from '@/components/form/FormPanel'
import Overlay from '@/components/overlay/Overlay'
import AddressesFormPart from '@/components/pages/addresses/AddressesFormPart'
import isEqual from 'lodash/isEqual'
import routeInfoType from '@/store/type/routeInfoType'
import AddressesHeaders from '@/pages/users/mixins/AddressesHeaders'

const mapper = MapperCollection.get(MapperType.USERS_FORM)
const UserApi = RestApiCollection.get(RestApiType.USERS)

export default {
    components: {
        UserAddressesTable,
        UserRouteTabs,
        AddressesFormPart,
        FormPanel,
        Overlay,
    },
    mixins: [
        TitleMixinGenerator(SelectApiClienType.USER, 'userId'),
        AddressesHeaders,
    ],
    props: {
        userId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            routeType,
            user: {},
            userForm: {},
            selectedCompanyAddresses: [],
            companyAddresses: [],
            total: 0,
            isCreate: false,
            busy: false,
        }
    },

    computed: {
        ...mapGetters({
            isSuperAdmin: authType.getters.IS_SUPER_ADMIN,
        }),
    },

    watch: {
        'userForm.companyAddresses': {
            handler(newVal) {
                this.selectedCompanyAddresses = []
                for (let val of newVal) {
                    const find = this.companyAddresses.find(
                        (el) => el['@id'] === val
                    )

                    this.selectedCompanyAddresses.push(find)
                }
            },
        },
    },

    mounted() {
        this.load()
    },

    methods: {
        async load() {
            this.busy = true

            const res = await this.$store.dispatch(
                routeInfoType.actions.GET_USER,
                this.userId
            )
            this.user = res

            if (res.company) {
                const data = await RestApiCollection.get(
                    RestApiType.COMPANY_ADDRESSES
                ).list({
                    page: 1,
                    itemsPerPage: 99,
                    'company[exact]': res.company['@id'],
                })

                this.companyAddresses = data['hydra:member']
                this.total = data['hydra:totalItems']
            }

            this.userForm = mapper.fromEntity(res)

            this.busy = false
        },

        async onInput(selections) {
            const ids = []
            for (let selection of selections) {
                ids.push(selection['@id'])
            }

            if (!isEqual(this.userForm.companyAddresses, ids)) {
                this.userForm.companyAddresses = ids

                this.busy = true
                await UserApi.update(
                    mapper.toEntity(this.userForm),
                    this.userId
                )
                this.busy = false
            }
        },

        onCreate() {
            this.$refs.addressTable.$refs.iterator.updateItems(true, true)
            this.isCreate = false
        },
    },
}
</script>
